/* 
    This is Kafood App Policy, it is very important in Kafood App so it cannot be edited or deleted.
*/

import React, { useEffect } from "react";
import { Breadcrumb } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "../blog/blog.scss";

const KafoodAppPolicy = () => {
  useEffect(() => {
    document.title = "Chính sách App Kafood";
  });

  return (
    <div className="detail_bg" id="page_blog">
      <div className="text_title">
        <h1>CHÍNH SÁCH APP KAFOOD</h1>
      </div>

      <div className="container pt-3 pb-2 pb-md-0 ps-md-4 bg-light">
        <Breadcrumb className="my-3">
          <Breadcrumb.Item linkAs={NavLink} linkProps={{ to: "/" }}>
            Trang chủ
          </Breadcrumb.Item>
          <Breadcrumb.Item>Chính sách App Kafood</Breadcrumb.Item>
        </Breadcrumb>

        <div className="blog-content">
          <h2>1. Giới thiệu</h2>

          <p>
            Chúng tôi cam kết bảo vệ sự riêng tư và bảo mật thông tin cá nhân
            của người dùng. Chính sách bảo mật này nhằm giải thích cách chúng
            tôi thu thập, sử dụng, bảo vệ và chia sẻ thông tin cá nhân trong quá
            trình sử dụng ứng dụng Kafood.
          </p>

          <p>
            Ứng dụng Kafood không hướng đến trẻ em dưới 13 tuổi sử dụng độc lập.
            Mọi dữ liệu liên quan đến học sinh đều do giáo viên nhập và được
            quản lý bởi nhà trường với sự đồng ý của phụ huynh.
          </p>

          <h2>2. Thông tin thu thập</h2>
          <p>
            Chúng tôi không thu thập thông tin trực tiếp từ trẻ em dưới 13 tuổi.
          </p>
          <p>Tất cả thông tin cá nhân đều do phụ huynh hoặc giáo viên nhập.</p>
          <h3>2.1. Thông tin cá nhân</h3>

          <ul>
            <li>
              <p>
                Họ tên phụ huynh, họ tên học sinh, số điện thoại, email – do phụ
                huynh nhập khi đăng ký tài khoản tại trường học.
              </p>
            </li>
            <li>
              <p>
                Dữ liệu kỹ thuật (địa chỉ IP, thông tin thiết bị) – chỉ dùng để
                tối ưu ứng dụng, không dùng để theo dõi cá nhân.
              </p>
            </li>
          </ul>

          <h3>2.2. Dữ liệu sức khỏe</h3>

          <ul>
            <li>
              <p>
                Chiều cao, cân nặng của học sinh được nhập bởi giáo viên nhà
                trường, với sự đồng ý của nhà trường và phụ huynh.
              </p>
            </li>
            <li>
              <p>
                Công ty Katec không chỉnh sửa, xử lý hoặc chịu trách nhiệm về
                nội dung dữ liệu này.
              </p>
            </li>
          </ul>

          <h3>2.3. Khẩu phần ăn và dữ liệu dinh dưỡng</h3>

          <ul>
            <li>
              <p>
                Thực đơn và suất ăn của học sinh được nhập vào hệ thống bởi giáo
                viên trong nhà trường.
              </p>
            </li>
            <li>
              <p>
                Công ty Katec chỉ cung cấp nền tảng lưu trữ, không can thiệp vào
                dữ liệu.
              </p>
            </li>
          </ul>

          <h3>2.4. Dữ liệu kỹ thuật</h3>

          <ul>
            <li>
              <p>
                Thông tin thiết bị, hệ điều hành, địa chỉ IP, dữ liệu sử dụng
                ứng dụng.
              </p>
            </li>
            <li>
              <p>
                Firebase: Chỉ sử dụng để gửi thông báo đẩy, không lưu trữ hoặc
                thu thập dữ liệu cá nhân.
              </p>
            </li>
          </ul>

          <h2>3. Mục đích sử dụng thông tin</h2>

          <p>Thông tin được thu thập nhằm:</p>

          <ul>
            <li>
              <p>
                Hỗ trợ phụ huynh theo dõi sức khỏe và chế độ ăn uống của học
                sinh.
              </p>
            </li>
            <li>
              <p>
                Gửi thông báo từ nhà trường đến phụ huynh thông qua Firebase.
              </p>
            </li>
            <li>
              <p>Cải thiện chất lượng dịch vụ.</p>
            </li>
          </ul>

          <h2>4. Thời gian lưu trữ</h2>

          <ul>
            <li>
              <p>
                Dữ liệu sức khỏe và khẩu phần ăn được lưu trữ trong hệ thống
                Website Kafood cho đến khi có yêu cầu hủy bỏ từ nhà trường hoặc
                sau 5 năm.
              </p>
            </li>
            <li>
              <p>
                Dữ liệu kỹ thuật chỉ được lưu trữ trong thời gian cần thiết để
                phục vụ mục đích vận hành ứng dụng.
              </p>
            </li>
          </ul>

          <h2>5. Những người hoặc tổ chức có thể được tiếp cận thông tin</h2>

          <ul>
            <li>
              <p>Giáo viên và phụ huynh liên quan đến học sinh.</p>
            </li>
            <li>
              <p>Cơ quan chức năng khi có yêu cầu hợp pháp.</p>
            </li>
            <li>
              <p>
                Công ty Katec chỉ cung cấp nền tảng lưu trữ, không xử lý hoặc
                chia sẻ dữ liệu.
              </p>
            </li>
          </ul>

          <h2>6. Địa chỉ đơn vị thu thập và quản lý thông tin</h2>

          <p>Công ty Cổ phần Công nghệ Katec</p>

          <ul>
            <li>
              <p>
                Địa chỉ: 189 Phan Huy Chú, phường An Khánh, Quận Ninh Kiều,
                Thành phố Cần Thơ, Việt Nam.
              </p>
            </li>
            <li>
              <p>Số điện thoại: 0889 881010</p>
            </li>
            <li>
              <p>Email: katec.cantho@gmail.com</p>
            </li>
          </ul>

          <h2>
            7. Phương thức và công cụ để người dùng tiếp cận và chỉnh sửa dữ
            liệu cá nhân
          </h2>

          <ul>
            <li>
              <p>
                Phụ huynh có thể truy cập ứng dụng để xem thông tin con của mình
                nhưng không thể chỉnh sửa dữ liệu.
              </p>
            </li>
            <li>
              <p>
                Nếu có yêu cầu cập nhật, vui lòng liên hệ nhà trường, nơi chịu
                trách nhiệm nhập và quản lý dữ liệu.
              </p>
            </li>
            <li>
              <p>
                Để yêu cầu hỗ trợ kỹ thuật, người dùng có thể liên hệ Katec qua
                email: katec.cantho@gmail.com.
              </p>
            </li>
          </ul>

          <h2>8. Cam kết bảo mật</h2>

          <ul>
            <li>
              <p>
                Dữ liệu được lưu trữ an toàn trên hệ thống máy chủ của Katec.
              </p>
            </li>
            <li>
              <p>
                Áp dụng các biện pháp mã hóa, giới hạn truy cập và kiểm tra bảo
                mật định kỳ.
              </p>
            </li>
            <li>
              <p>
                Không chia sẻ thông tin cá nhân cho bên thứ ba khi chưa có sự
                đồng ý.
              </p>
            </li>
          </ul>

          <h2>9. Cách bảo vệ dữ liệu trẻ em</h2>

          <ul>
            <li>
              <p>
                Không cho phép trẻ em nhập thông tin cá nhân trực tiếp vào ứng
                dụng.
              </p>
            </li>
            <li>
              <p>Không chia sẻ dữ liệu cá nhân của trẻ em với bên thứ ba.</p>
            </li>
            <li>
              <p>Mã hóa dữ liệu và kiểm tra bảo mật định kỳ.</p>
            </li>
          </ul>

          <h2>10. Quyền lợi của người dùng</h2>

          <p>Phụ huynh có quyền:</p>

          <ul>
            <li>
              <p>Truy cập và theo dõi thông tin về con của mình.</p>
            </li>
            <li>
              <p>
                Yêu cầu xóa dữ liệu cá nhân bằng cách liên hệ với nhà trường
                hoặc Katec.
              </p>
            </li>
            <li>
              <p>Từ chối nhận thông báo từ ứng dụng.</p>
            </li>
          </ul>

          <h2>11. Cập nhật chính sách</h2>

          <ul>
            <li>
              <p>
                Chính sách có thể được cập nhật và thông báo qua ứng dụng hoặc
                trang web chính thức.
              </p>
            </li>
          </ul>

          <h2>12. Chấp thuận</h2>

          <p>
            Bằng cách sử dụng ứng dụng Kafood, người dùng đồng ý với các điều
            khoản của chính sách bảo mật này.
          </p>

          <h2>Liên hệ hỗ trợ hoặc giải quyết khiếu nại:</h2>

          <ul>
            <li>
              <p>
                Email: <strong>katec.cantho@gmail.com</strong>
              </p>
            </li>
            <li>
              <p>
                Số điện thoại: <strong>0889 881010</strong>
              </p>
            </li>
            <li>
              <p>
                Chúng tôi sẽ xử lý hỗ trợ hoặc khiếu nại trong vòng 7 ngày làm
                việc.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default KafoodAppPolicy;
